/* eslint-disable no-undef */
import '../App.css';
import React from 'react';
// import Footer from '../Component/Footer';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Modal from 'react-bootstrap/Modal';
import Header from '../Component/Header';
// import { isMobile } from 'react-device-detect';
import { Form, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
import { findPermutations } from '../utility/Permutation'
import { processPlatformPosition } from '../utility/processPlatform';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
// import { isCompositeComponent } from 'react-dom/test-utils';
import Alert from 'react-bootstrap/Alert'
import CheckMark from '../Images/CheckMark.png';
import Loading3 from '../Images/loading.gif';
// import Select from 'react-select';
import {ReturnCancelValidation} from '../static/ReturnCancelValidation';
const { CancelBet, getKeyInFormat, CreateBetOrder, getUserKeyIn, getOrderOriginalText, searchDownlineHR, getMyselfF } = require('../Api');
var timer
var timer2

class betting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      USerID: this.props.userID,
      username: this.props.username,
      token: this.props.token,
      userID: this.props.userID,
      modalVisible: false,
      modalShow: false,
      CheckDate: true,
      lineData: [
        {
          originalWord: 'D#',
          translatedWord: 'D#',
          FinalOriginalWord: '',
        },
      ],
      lineData2: [
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        }
      ],
      SelectedLine: 0,
      Formula: {},
      OverallAmount: 0,
      WordLine: 0,
      WordLineLoop: [],
      DisplayFormula: '',
      displayNumber: '',
      ModalDate: [{ Date: '' }],
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      PressCount: 0,
      RedNumberApiResult: [],
      RedNumberArray: [],
      Receipt: '',
      ShowReceipt: false,
      getUsers: [],
      UserIDforCancel: '',
      OriTextforCancel: '',
      RoleforCancel: '',
      ServerIDforCancel: '',
      loading: false,
      SuccessFullCancel: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      GamesDisplay: '',
      ReceiptDisplay: '',
      optionsUsername: '',
      SelectedUser: '',
      MultiplyDivide: '',
      JinNanCheck: 0,
      ButtonSend: '#3C96F4',
      ButtonClear: '#EE3E13',
      OneStar: '',
      DisabledCancel: false,
      CurrentPFLine: '',
      Balance: 0,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
      getUserFormula: {},
    }
    this.NextLineButtonFunction = this.NextLineButtonFunction.bind(this);
    this.setModalVisible = this.setModalVisible.bind(this);
    this.ReverseNumFunction = this.ReverseNumFunction.bind(this);
    this.SelectLineFunction = this.SelectLineFunction.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
  }

  async ConfirmSaveBet() {
    this.setState({loading: true, disabled: true});
    const username = this.state.username;
    const userID = this.state.userID;
    const token = this.state.token;
    const lineData = this.state.lineData;
    const lineDatalength = lineData.length;
    const OriwordArray = [];
    let Platformvalidate = false;
    let BetWordValidate = false;
    let ErrorLine = [];
    let ErrorPlatform = []

    for (var i = 0; i < lineDatalength; i++) {
      if (lineData[i].originalWord.includes('D#')) {
        OriwordArray.push(lineData[i].originalWord + '\n');
      } else if (
        lineData[i].originalWord !== '' && 
          this.state.JinNanCheck ===0 &&
              !lineData[i].originalWord.includes('#')
      ) {
        OriwordArray.push(lineData[i].originalWord + '\n');
      } else if(
        lineData[i].originalWord !== '' && 
          this.state.JinNanCheck === 1 &&
              lineData[i].originalWord.startsWith('#') &&
                lineData[i].originalWord.split('#').length === 2) {
        OriwordArray.push(lineData[i].originalWord + '\n');
      } else if (
        lineData[i].originalWord !== '' &&
              !lineData[i].originalWord.includes('#') && 
                lineData[i].originalWord.split('').includes('9')
      ) {
        ErrorLine.push(i + 1);
      } else if (
        (lineData[i].originalWord !== '' &&
              lineData[i].originalWord.includes('#') &&
              lineData[i].BLineAmount <= 0) ||
              lineData[i].translatedWord.includes('undefined')
      ) {
        OriwordArray.push(lineData[i].originalWord + '\n');
        // ErrorLine.push(i + 1);
      } else if (
        lineData[i].originalWord !== '' &&
              isNaN(lineData[i].BLineAmount) !== false
      ) {
        OriwordArray.push(lineData[i].originalWord + '\n');
        // ErrorLine.push(i + 1);
      } else if (
        lineData[i].originalWord !== '' &&
              lineData[i].originalWord.includes('#') &&
              lineData[i].BLineAmount > 0
      ) {
        OriwordArray.push(lineData[i].originalWord + '\n');
      }
      // if(this.state.JinNanCheck === 1){
      //   if (
      //     lineData[i].originalWord !== '' &&
      //           lineData[i].originalWord.startsWith('#') && 
      //             lineData[i].originalWord.split('#').length === 2 &&
      //               lineData[i].originalWord.split('').includes('9')
      //   ) {
      //     ErrorPlatform.push(i + 1);
      //   }
      // } else {
      //   if (
      //     lineData[i].originalWord !== '' &&
      //           !lineData[i].originalWord.includes('#') && 
      //             lineData[i].originalWord.split('').includes('9')
      //   ) {
      //     ErrorPlatform.push(i + 1);
      //   }
      // }
    }
    for(var a = 0; a < OriwordArray.length; a++) {
      if(a !== 0) {
        if(this.state.JinNanCheck === 1) {
          if(OriwordArray[1].startsWith('#')) {
            Platformvalidate = true
          }
        } else {
          if(!OriwordArray[1].includes('#')) {
            Platformvalidate = true
          }
        }
        if(OriwordArray[a].includes('#') === true) {
          BetWordValidate = true
        }
      }
    }
    if(Platformvalidate === false) {
      this.openNotification('PlatformError')
      this.setState({ notificationType: 'error', loading: false, disabled: false });
    } else if (BetWordValidate === false) {
      this.openNotification('BEmpty')
      this.setState({ notificationType: 'error', loading: false, disabled: false });
    } else if (ErrorLine.length > 0) {
      this.openNotification('BEmpty')
      this.setState({ notificationType: 'error', loading: false, disabled: false });
    } 
    else if (ErrorPlatform.length > 0) {
      this.openNotification('ErrorPlatform', `${ErrorPlatform}`)
      this.setState({ notificationType: 'error', loading: false, disabled: false });
    } 
    // else if (Over7pm === false) {
    //   this.openNotification('ExceededHoursNormal')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false });
    // }
    else {
      const PlaceBetOrder = await CreateBetOrder(userID, OriwordArray.join(''), token, username, true);
      console.log('PlaceBetOrder', PlaceBetOrder)
      if(PlaceBetOrder.error) {
        this.openNotification(ReturnBetValidation(PlaceBetOrder.error), (PlaceBetOrder.balance !== undefined) ? PlaceBetOrder.balance : '' )
        this.setState({ notificationType: 'error', loading: false, disabled: false });
      } else {
        clearTimeout(timer2)
        timer2 = setTimeout(() => {
          this.SelectedUser(this.state.SelectedUser, '')
        }, 1000);
        this.setState({
          ShowReceipt: true,
          SuccessFullCancel: false,
          Receipt: PlaceBetOrder.receipt,
          UserIDforCancel: PlaceBetOrder.UserID,
          OriTextforCancel: PlaceBetOrder.OriginalText,
          RoleforCancel: PlaceBetOrder.Role,
          ServerIDforCancel: PlaceBetOrder.ServerID,
          loading: false,
          disabled: false,
        }, ()=> {
          this.openNotification('BetSuccess')
          this.setState({ notificationType: 'success' });
          this.ConvertResultRed(this.state.Receipt)
        })
        this.CleanData();
      }
    }
    this.setState({loading: false});
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ReceiptDisplay: returnWord});
    } else {
      this.setState({ReceiptDisplay: Word});
    }
  }

  async handleSearchText(e, field) {
    const value = (e.target.value).replace(/[^0-9]/g, '')
    this.setState({ [field]: value })
  }

  async CancelBetAPI() {
    this.setState({ DisabledCancel: true })
    // const Receipt = this.state.Receipt
    // const UserID = this.state.UserIDforCancel
    // const OriText = this.state.OriTextforCancel
    // const Role = this.props.Role
    const ServerID = this.state.ServerIDforCancel
    
    const CancelBets = await CancelBet(ServerID, this.props.username, this.props.token)
    if (CancelBets.error) {
      this.openNotification(ReturnCancelValidation(CancelBets.error))
      this.setState({ notificationType: 'error', DisabledCancel: false });
    } else {
      if(CancelBets.message === 'Cancel Successful') {
        this.setState({ 
          UserIDforCancel: '',
          OriTextforCancel: '',
          RoleforCancel: '',
          // ServerIDforCancel: '',
          Receipt: CancelBets.CancelReceipt,
          SuccessFullCancel: true,
          DisabledCancel: false,
        }, ()=> {
          this.ConvertResultRed(this.state.Receipt)
        });
      }
    }
    // if(CancelBets.error === 'database error') {
    //   this.openNotification('databaseError')
    //   this.setState({ notificationType: 'error' });
    // } else if (CancelBets.error === 'Exceed Date') {
    //   this.openNotification('ExceededDate')
    //   this.setState({ notificationType: 'error' });
    // } else if(CancelBets.error === 'OverTimeLimit') {
    //   this.openNotification('ExceededHours')
    //   this.setState({ notificationType: 'error' });
    // } else {
    //   if(CancelBets.message === 'Cancel Successful') {
    //     this.setState({ 
    //       UserIDforCancel: '',
    //       OriTextforCancel: '',
    //       RoleforCancel: '',
    //       // ServerIDforCancel: '',
    //       Receipt: CancelBets.CancelReceipt,
    //       SuccessFullCancel: true,
    //     }, ()=> {
    //       this.ConvertResultRed(this.state.Receipt)
    //     });
    //   }
    // }
  }

  async setModalVisible() {
    this.GetModalDate();
    const CheckHidePass = this.state.modalShow;
    if (CheckHidePass === true) {
      this.setState({ modalShow: false });
    } else {
      this.setState({ modalShow: true });
    }
  }

  GetModalDate() {
    const DateLoop = [];
    if (moment().format('HH:mm') >= '19:15') {
      for (let i = 0; i < 7; i++) {
        const TodayDate = moment();
        let Date = moment(TodayDate).add(i + 1, 'days');
        DateLoop.push({
          Date: moment(Date).format('DDMM(ddd)'),
          DateProcess: moment(Date).format('DDMMYY'),
          CheckMark: false,
        });
      }
    } else {
      for (let i = 0; i < 7; i++) {
        const TodayDate = moment();
        let Date = moment(TodayDate).add(i, 'days');
        DateLoop.push({
          Date: moment(Date).format('DDMM(ddd)'),
          DateProcess: moment(Date).format('DDMMYY'),
          CheckMark: false,
        });
      }
    }
    return {DateLoop};
  }

  async componentDidMount() {
    this.SetPlatformLine();
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    }
    this.setState({ OneStar: UserAuthorities.OneStarBao, USerID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID })
    let { ReOrderBet, SelectedUser } = this.props.location

    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({
      GamesDisplay: GDisplay,
    })
    const DateLoop = this.GetModalDate();
    const getUserKeyInResult = await getKeyInFormat(
      this.props.username,
      this.props.token,
    );
    this.setState({
      Formula: getUserKeyInResult,
      DisplayFormula: getUserKeyInResult.KeyIn1.toString(),
      ModalDate: DateLoop || [],
      MultiplyDivide: getUserKeyInResult.MultiplyDivide,
      Balance: getUserKeyInResult.CashWallet,
      getUserFormula: getUserKeyInResult.XingJinFormula,
    });

    if (ReOrderBet !== undefined) {
      this.setState({
        Formula: getUserKeyInResult,
        DisplayFormula: getUserKeyInResult.KeyIn1.toString(),
        ModalDate: DateLoop,
        UserID: this.props.userID,
        Username: this.props.username,
        WordLine: (ReOrderBet || '').length,
        getUserFormula: getUserKeyInResult.XingJinFormula,
      });
      this.SelectedUser(SelectedUser, 'PassReceipt');
      this.getDownline('PassReceipt', SelectedUser);
      this.ProcessWords(ReOrderBet);
    } else {
      this.getDownline();
    }
  }

  SetPlatformLine() {
    const GameBetting = JSON.parse(this.props.UserAuthority).GameBetting
    // const GameBetting = '123564987'
    this.setState({ CurrentPFLine: GameBetting})
  }

  async getDownline(Check, SelectedUser) {
    const UserID = this.state.USerID;
    const Username = this.state.username;
    const token = this.state.token

    const getMyself = await getMyselfF(UserID, token, Username)
    if(getMyself) {
      if(Check === 'PassReceipt') {
        this.setState({ userID: SelectedUser.value, SelectedUser: SelectedUser, SearchUser: SelectedUser.label }, () => {
          this.getTargetUserKeyIn(this.state.userID);
        })
      } else {
        this.setState({ userID: getMyself[0].ID, SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, JinNanCheck: getMyself[0].JinNan, SearchUser: getMyself[0].Username, getUserFormula: getMyself[0].XingJinFormula }, () => {
          this.getTargetUserKeyIn(this.state.userID);
        })
      } 
    }
  }

  async SelectedUsername(e, users) {
    this.setState({ userID: e.target.value}, ()=> {
      this.getTargetUserKeyIn(this.state.userID);
    })
  }

  SelectedUser(e, PassReceipt) {
    if(PassReceipt === 'PassReceipt') {
      this.setState({ userID: e.value, SelectedUser: e, SearchUser: e.label}, () => {
        this.getTargetUserKeyIn(this.state.userID, 'PassReceipt');
        this.handleCloseSearchModal();
      })
    } else {
      this.setState({ userID: e.value, SelectedUser: e, SearchUser: e.label}, () => {
        this.getTargetUserKeyIn(this.state.userID);
        this.handleCloseSearchModal();
      })
    }
  }

  async getTargetUserKeyIn(TargetUserID, PassReceipt) {
    const UserKeyIn = await getUserKeyIn(TargetUserID, this.props.token, this.props.username)
    if(UserKeyIn) {
      // const TestingPla = '123564987'
      this.setState({ Formula: UserKeyIn[0]  || [],
        DisplayFormula: UserKeyIn[0].KeyIn1.toString(),
        MultiplyDivide: UserKeyIn[0].MultiplyDivide,
        JinNanCheck: UserKeyIn[0].JinNan,
        OneStar: UserKeyIn[0].OneStarBao,
        CurrentPFLine: UserKeyIn[0].GameBetting,
        // CurrentPFLine: TestingPla,
        Balance: UserKeyIn[0].CashWallet,
        getUserFormula: UserKeyIn[0].XingJinFormula,
      }, ()=> {
        if(PassReceipt === 'PassReceipt') {
          this.convertPlatformbyJinnan('PassReceipt')
        } else {
          this.convertPlatformbyJinnan()
        }
        
      })
    }
  }

  convertPlatformbyJinnan(PassReceipt) {
    let lineData = this.state.lineData
    if(this.state.JinNanCheck === 1) {
      if(PassReceipt === 'PassReceipt'){
        let BetValues = ''
        for(var c=0; c < lineData.length; c++) {
          let OriginalWord = lineData[c].originalWord
          if (OriginalWord.startsWith('##')) {
            OriginalWord = OriginalWord.substring(2, OriginalWord.length);
          }
          const Back = OriginalWord.substring(OriginalWord.indexOf('#'))
          if(!OriginalWord.includes('D') && OriginalWord.includes('#') && OriginalWord.split('#').length !== 2) {
            BetValues = Back
          }
          if(!lineData[c].originalWord.includes('#') && lineData[c].originalWord.split('#').length === 1 && lineData[c].originalWord !== '') {
            lineData[c].originalWord = OriginalWord + BetValues
          }
        }
      } else {
        for(var i=0; i < lineData.length; i++) {
          if(!lineData[i].originalWord.startsWith('#') && !lineData[i].originalWord.includes('#') && lineData[i].originalWord.split('#').length === 1 && lineData[i].originalWord !== '') {
            lineData[i].originalWord = `#${lineData[i].originalWord}`
          }
        }
      }
    } else {
      for(var a=0; a < lineData.length; a++) {
        if(lineData[a].originalWord.startsWith('#') && lineData[a].originalWord.split('#').length === 2) {
          lineData[a].originalWord = lineData[a].originalWord.substring(1, lineData[a].originalWord.length)
        }
      }
    }
    this.setState({ lineData }, ()=> {
      this.ProcessWords(this.state.lineData)
    })
  }

  async SelectDateFunction(Index) {
    const toProcessDateData = this.state.ModalDate;
    if (toProcessDateData.DateLoop[Index.Index].CheckMark === false) {
      toProcessDateData.DateLoop[Index.Index].CheckMark = true;
    } else {
      toProcessDateData.DateLoop[Index.Index].CheckMark = false;
    }
    this.setState({ ModalDate: toProcessDateData });
  }

  onPressConfirmSelectDate() {
    const toProcessDateData = this.state.ModalDate;
    const lineData = this.state.lineData;
    let DateData = '';
    const CheckDate = toProcessDateData.DateLoop.filter(
      item => item.CheckMark === true,
    );
    if (CheckDate.length > 0) {
      for (let i = 0; i < CheckDate.length; i++) {
        if (i === 0) {
          DateData = 'D#**' + CheckDate[i].DateProcess;
        } else {
          DateData += '*' + CheckDate[i].DateProcess;
        }
      }
      lineData[0].originalWord = DateData;
      this.NextLineButtonFunction({ key: 'Enter' }, 0, 'Nextline');
    } else {
      lineData[0].originalWord = 'D#';
      this.NextLineButtonFunction({ key: 'Enter' }, 0, 'Nextline');
    }
    this.ProcessWords(lineData);
    this.setState({modalShow: false});
  }

  async ProcessWords(lineData) {
    // const CurrentPFLine = this.state.CurrentPFLine.split('') || ""
    // const PlatformDdd = {
    //   '1': 'M',
    //   '2': (this.state.GamesDisplay === 'MKTSWBD') ? 'K' : 'P',
    //   '3': 'T',
    //   '4': 'S',
    //   '5': 'W',
    //   '6': 'B',
    //   '7': (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K',
    //   '8': '8',
    // }
    const toProcessLineData = lineData || this.state.lineData;
    let numberOfPlatform = 0;
    let OverallAmount = 0;
    let keyInFormulaDisplay = '';
    for (let i = 0; i < toProcessLineData.length; i++) {
      const thisLineWord = toProcessLineData[i].originalWord;
      // process date data
      if (i === 0) {
        toProcessLineData[i].translatedWord = toProcessLineData[i].originalWord;
        if (toProcessLineData[i].translatedWord !== '') {
          if (this.state.SelectedLine !== 0) {
            const { newLineWord } = this.processDate(
              toProcessLineData[i].originalWord,
            );
            if (newLineWord.substring(2) >= 9) {
              toProcessLineData[i].translatedWord = 'D#';
              toProcessLineData[i].originalWord = 'D#';
              this.openNotification('Warning')
              this.setState({ notificationType: 'error' });
            } else {
              toProcessLineData[i].originalWord = ((toProcessLineData[i].originalWord).replace(/[^0-9*]/g, '') >= 9) ? 'D#' :
                ('D#' + (toProcessLineData[i].originalWord).replace(/[^0-9*]/g, ''));
              toProcessLineData[i].translatedWord = newLineWord;
            }
          }
        } else {
          toProcessLineData[i].originalWord = 'D#';
        }
      } else if (this.state.SelectedLine === i) {
        // this line highlighted
        if (thisLineWord.includes('#')) {
          // this line highlighted consist formula
          const { newLineWord, BLineAmount, CalculateLineAmount, Mode, keyInFormula } =
                        this.processFormula(
                          toProcessLineData[i].originalWord,
                          numberOfPlatform,
                        );
          toProcessLineData[i].translatedWord = newLineWord;
          toProcessLineData[i].BLineAmount = BLineAmount;
          toProcessLineData[i].CalculateLineAmount = CalculateLineAmount;
          toProcessLineData[i].Mode = Mode;
          OverallAmount += CalculateLineAmount;
          keyInFormulaDisplay = keyInFormula.toString();
          // this.setState({ DisplayFormula: keyInFormula.toString() });
        } else {
          toProcessLineData[i].translatedWord =
                        toProcessLineData[i].originalWord;
          toProcessLineData[i].Mode = '';
          // this.setState({ DisplayFormula: this.state.Formula.KeyIn1 || [] });
          keyInFormulaDisplay = this.state.Formula.KeyIn1.toString();
        }
      } else {
        if (
          thisLineWord.startsWith('#') &&
          this.state.JinNanCheck === 1 &&
          thisLineWord.split('#').length === 2
        ) {
          let ProcessPlat = processPlatformPosition(this.state.CurrentPFLine ,this.state.GamesDisplay ,toProcessLineData[i].originalWord, '1')
          toProcessLineData[i].Mode = '';
          toProcessLineData[i].BLineAmount = 0;
          numberOfPlatform = toProcessLineData[i].originalWord.length - 1;
          toProcessLineData[i].translatedWord = `#${ProcessPlat.join('')}`
        } else if (thisLineWord.includes('#')) {
          const {newLineWord, BLineAmount, CalculateLineAmount, Mode} =
            this.processFormula(
              toProcessLineData[i].originalWord,
              numberOfPlatform,
            );
          toProcessLineData[i].translatedWord = newLineWord;
          toProcessLineData[i].BLineAmount = BLineAmount;
          toProcessLineData[i].CalculateLineAmount = CalculateLineAmount;
          toProcessLineData[i].Mode = Mode;
          OverallAmount += CalculateLineAmount;
        } else if (thisLineWord !== '' && this.state.JinNanCheck === 0) {
          let ProcessPlat = processPlatformPosition(this.state.CurrentPFLine ,this.state.GamesDisplay ,toProcessLineData[i].originalWord, '0')
          toProcessLineData[i].Mode = '';
          numberOfPlatform = toProcessLineData[i].originalWord.length;
          toProcessLineData[i].translatedWord = ProcessPlat.join('')
        }
      }
    }
    this.setState({
      DisplayFormula: keyInFormulaDisplay,
      lineData: toProcessLineData,
      OverallAmount,
    });
  }

  processDate(originalWord) {
    let toProcessArray = '';
    let newLineWord = '';
    let numberOfday = 1;
    let ProcessDate = '';
    const dateMode = originalWord.substring(2, originalWord.length);
    // toProcessArray = dateMode.split('*');
    if (dateMode === '*') {
      newLineWord = 'D#AUTO';
    } else if (dateMode.startsWith('**')) {
      toProcessArray = dateMode.replace('**', '');
      toProcessArray = toProcessArray.split('*');
      for (let j = 0; j < toProcessArray.length; j += 1) {
        numberOfday = toProcessArray.length;
        ProcessDate += moment(toProcessArray[j], 'DDMMYY').format('DDMM(ddd)');
      }
      newLineWord = 'D#' + numberOfday + 'Days ' + ProcessDate;
      // specific date
    } else if (dateMode.startsWith('*')) {
      // const todayDate = moment().format('DDMM(ddd)');
      let buyDate = [];
      toProcessArray = dateMode.replace('*', '');
      toProcessArray = toProcessArray.split('');
      numberOfday = toProcessArray.length;
      toProcessArray.forEach(item => {
        let toCompareTodayDate = moment().format('YYMMDD');
        let toCompareDate = moment().day(item).format('YYMMDD');
        let convertDate = moment().day(item).format('DDMM(ddd)');
        if (toCompareDate < toCompareTodayDate) {
          convertDate = moment()
            .day(Number(item) + 7)
            .format('DDMM(ddd)');
        }
        buyDate.push(convertDate);
      });
      newLineWord = 'D#' + numberOfday + 'Days ' + buyDate;
      // specific day of week
    } else {
      newLineWord = originalWord;
    }
    return {newLineWord};
  }

  // processDate(originalWord) {
  //   let toProcessArray = '';
  //   let newLineWord = '';
  //   let numberOfday = 1;
  //   let ProcessDate = '';
  //   var format = /[#!@$%^&()_‘+\-=\]{};':"\\|,.<>?ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]+/;
  //   const dateMode = originalWord.substring(2, originalWord.length);

  //   // toProcessArray = dateMode.split('*');
  //   if (dateMode === '*') {
  //     newLineWord = 'D#AUTO';
  //   } else if (dateMode.startsWith('**')) {
  //     toProcessArray = dateMode.replace('**', '');
  //     toProcessArray = toProcessArray.split('*');
  //     for (let j = 0; j < toProcessArray.length; j += 1) {
  //       numberOfday = toProcessArray.length;
  //       ProcessDate += moment(toProcessArray[j], 'DDMMYY').format('DDMM(ddd)');
  //     }
  //     newLineWord = 'D#' + numberOfday + 'Days ' + ProcessDate;
  //     // specific date
  //   } else if (dateMode.startsWith('*')) {
  //     const todayDate = moment().format('DDMM(ddd)');
  //     let buyDate = [];
  //     toProcessArray = dateMode.replace('*', '');
  //     toProcessArray = toProcessArray.split('');
  //     numberOfday = toProcessArray.length;
  //     toProcessArray.forEach(item => {
  //       let convertDate = moment().day(item).format('DDMM(ddd)');
  //       if (convertDate < todayDate) {
  //         convertDate = moment()
  //           .day(Number(item) + 7)
  //           .format('DDMM(ddd)');
  //       }
  //       buyDate.push(convertDate);
  //     });
  //     newLineWord = 'D#' + numberOfday + 'Days ' + buyDate;
  //     // specific day of week
  //   } else if (dateMode < 7) {
  //     let NextDate = [];
  //     if(dateMode === '1') {
  //       const CreatedDate = (moment().hours() >= 19) ? moment(new Date().setDate(new Date().getDate() + (1))).format('DDMM(ddd)') 
  //         : moment(new Date()).format('DDMM(ddd)');
  //       NextDate.push(CreatedDate);
  //     } else {
  //       for (var i = 0; i < dateMode; i++) {
  //         const CreatedDate = moment(new Date().setDate(new Date().getDate() + (i + 1))).format('DDMM(ddd)');
  //         NextDate.push(CreatedDate);
  //       }
  //     }
  //     newLineWord = 'D#' + NextDate;
  //   } else if (format.test(dateMode)) {
  //     const filterDateMode = dateMode.replace(/[^0-9*]/g, '')
  //     if (filterDateMode === '') {
  //       newLineWord = 'D#'
  //     } else if (filterDateMode >= 7) {
  //       newLineWord = 'D#'
  //     } else if (filterDateMode < 7) {
  //       let ProcessDate = [];
  //       for (let j = 0; j < filterDateMode; j += 1) {

  //         const TodayDate = moment(new Date().setDate(new Date().getDate() + (j + 1))).format('DDMM(ddd)')
  //         ProcessDate.push(TodayDate)
  //       }
  //       newLineWord = 'D#' + ProcessDate;

  //     } else {
  //       newLineWord = 'D#'
  //     }
  //   } else {
  //     newLineWord = originalWord;
  //   }
  //   return { newLineWord };
  // }

  processFormula(originalWord, numberOfPlatform) {
    const OneStar = this.state.OneStar
    // 1 = Box *, IBOX ** 
    // 0 = Box **, IBOX *
    let toProcessArray = '';
    toProcessArray = originalWord.split('#');
    let onlyWord = originalWord.split('#')[0];
    let BackWord = originalWord.substring(originalWord.indexOf('#'));
    let RemoveDot = onlyWord.replace(/([*])/g, '');
    let newLineWord = '';
    let BLineAmount = 0;
    let CalculateLineAmount = 0;
    let keyInFormula = '';
    let Mode = '';
    let Mode2 = '';
    let WordQuantity = '';
    let getPermutations = '';
    let subStringWord = '';
    
    if(originalWord.length >= 8 && BackWord.endsWith('**')) {
      // console.log('toProcessArray',toProcessArray[1].replace('**', ''))
      keyInFormula = ['formula']
      Mode = 'formula'
      const getUserFormula = (this.state.getUserFormula === null) ? [] : this.state.getUserFormula
      var getformulas = Object.values(getUserFormula).filter(function (el) {
        return el.Num === toProcessArray[1].replace('**', '')
      });
      if (getformulas.length > 0) {
        const KeyinFormat = Object.keys(getformulas[0])
        const Key3Formulas = KeyinFormat.filter(function(item) {
          return !['Num'].includes(item); 
        })
        let ArrayResult = []
        Key3Formulas.forEach((items) => {
          ArrayResult.push((items === 'CA') ? 'A' : (items === 'CF') ? 'C' : items)
        })
        keyInFormula = ArrayResult
      }
    }
    if (RemoveDot.length === 3 && onlyWord.length === 3) {
      // keyInFormula = this.state.Formula.KeyIn1.filter(
      //   item => item === 'CA1' || item === 'CC',
      // );
      // if (keyInFormula.length === 0) {
      //   keyInFormula = ['CA1','CC']
      // }
      keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
      if (keyInFormula.length === 0) {
        keyInFormula = ['A', 'C']
      } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
        keyInFormula = ['A']
      } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
        keyInFormula = ['C']
      } else {
        keyInFormula = ['A', 'C']
      }
      WordQuantity = 1;
    } else if (RemoveDot.length === 2 && onlyWord.length === 2) {
      keyInFormula = ['PA', 'PF']
      WordQuantity = 1;
    } else if (RemoveDot.length === 4 && onlyWord.length === 4 && !BackWord.endsWith('**')) {
      keyInFormula = this.state.Formula.KeyIn1;
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 6
    ) {
      keyInFormula = this.state.Formula.KeyIn2;
      WordQuantity = 1;
    }  else if (
      RemoveDot.length === 3 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 5
    ) {
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 4
    ) {
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      WordQuantity = 1;
    }
    else if (
      RemoveDot.length === 4 &&
      onlyWord.length === 5 && onlyWord.startsWith('*') && toProcessArray[1] && toProcessArray[1].includes('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      Mode2 = 'XingJinFormula'
      Mode = (OneStar === 1) ? 'B' : 'IB';
    }
    else if (
      RemoveDot.length === 4 &&
      onlyWord.length === 6 && onlyWord.startsWith('**') && toProcessArray[1] && toProcessArray[1].includes('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      Mode2 = 'XingJinFormula'
      Mode = (OneStar === 1) ? 'B' : 'IB';
    }
    //formula 1
    else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 5
    ) {
      keyInFormula = ['formula']
      Mode = 'formula'
      const getUserFormula = (this.state.getUserFormula === null) ? [] : this.state.getUserFormula
      var getformula = Object.values(getUserFormula).filter(function (el) {
        return el.Num === toProcessArray[1]
      });
      if (getformula.length > 0) {
        const KeyinFormat = Object.keys(getformula[0])
        const Key3Formulas = KeyinFormat.filter(function(item) {
          return !['Num'].includes(item); 
        })
        let ArrayResult = []
        Key3Formulas.forEach((items) => {
          ArrayResult.push((items === 'CA') ? 'A' : (items === 'CF') ? 'C' : items)
        })
        keyInFormula = ArrayResult
      }
    }
    else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 7
    ) {
      WordQuantity = 1;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE']
    } 
    else if (
      RemoveDot.length === 3 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 6
    ) {
      WordQuantity = 1;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE']
    } 
    else if (
      RemoveDot.length === 2 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 5
    ) {
      WordQuantity = 1;
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE']
    } 
    // else if (
    //   RemoveDot.length === 3 &&
    //   onlyWord.endsWith('*') &&
    //   onlyWord.length === 4
    // ) {
    //   keyInFormula = ['formula']
    // } 
    else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 4
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      // keyInFormula = this.state.Formula.KeyIn1.filter(
      //   item => item === 'CA1' || item === 'CC',
      // );
      // if (keyInFormula.length === 0) {
      //   keyInFormula = ['CA1','CC']
      // }
      keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
      if (keyInFormula.length === 0) {
        keyInFormula = ['A', 'C']
      } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
        keyInFormula = ['A']
      } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
        keyInFormula = ['C']
      } else {
        keyInFormula = ['A', 'C']
      }
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 3
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = ['PA', 'PF']
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 5
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      // keyInFormula = this.state.Formula.KeyIn1.filter(
      //   item => item === 'CA1' || item === 'CC',
      // );
      // if (keyInFormula.length === 0) {
      //   keyInFormula = ['CA1','CC']
      // }
      keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
      if (keyInFormula.length === 0) {
        keyInFormula = ['A', 'C']
      } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
        keyInFormula = ['A']
      } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
        keyInFormula = ['C']
      } else {
        keyInFormula = ['A', 'C']
      }
      Mode = (OneStar === 1) ? 'IB' : 'B';
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 4
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = ['PA', 'PF']
      Mode = (OneStar === 1) ? 'IB' : 'B';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 4 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      // keyInFormula = this.state.Formula.KeyIn1.filter(
      //   item => item === 'CA1' || item === 'CC',
      // );
      // if (keyInFormula.length === 0) {
      //   keyInFormula = ['CA1','CC'];
      // }
      keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
      if (keyInFormula.length === 0) {
        keyInFormula = ['A', 'C']
      } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
        keyInFormula = ['A']
      } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
        keyInFormula = ['C']
      } else {
        keyInFormula = ['A', 'C']
      }
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 6 &&
      RemoveDot.length === 3 && 
      onlyWord.endsWith('**')
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF']
      Mode = '1{234}';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 6 &&
      RemoveDot.length === 3 && 
      onlyWord.endsWith('**')
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF']
      Mode = '{123}4';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 4 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      // keyInFormula = this.state.Formula.KeyIn1.filter(
      //   item => item === 'CA1' || item === 'CC',
      // );
      // if (keyInFormula.length === 0) {
      //   keyInFormula = ['CA1','CC']
      // }
      keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
      if (keyInFormula.length === 0) {
        keyInFormula = ['A', 'C']
      } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
        keyInFormula = ['A']
      } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
        keyInFormula = ['C']
      } else {
        keyInFormula = ['A', 'C']
      }
      Mode = '{123}4';
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 5
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } 
    else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } 
    else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('***')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE'];
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } 
    else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('***')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE'];
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } 
    // 3and2D
    else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 5 &&
      onlyWord.endsWith('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? getPermutations.length : 1;
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } 
    else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 6
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = (OneStar === 1) ? 'IB' : 'B';
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = (OneStar === 1) ? 'IB' : 'B';
    } 
    else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 9 &&
      onlyWord.endsWith('***')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      Mode = (OneStar === 1) ? 'IB' : 'B';
    }
    else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE'];
      Mode = (OneStar === 1) ? 'IB' : 'B';
    }
    else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('***')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE'];
      Mode = (OneStar === 1) ? 'IB' : 'B';
    }
    //3Dand2D
    else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = (OneStar === 1) ? 'IB' : 'B';
    } 
    else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('**')
    ) {
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = (OneStar === 1) ? 1 : getPermutations.length;
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      Mode = (OneStar === 1) ? 'IB' : 'B';
    } 
    else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 5 &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = '1{234}';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 5 &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = '{123}4';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = '{123}4';
    } else if (RemoveDot.includes('-')) {
      // RUN RANGE
      let StartNum = RemoveDot.split('-')[0];
      let ToNum = RemoveDot.substring(RemoveDot.indexOf('-') + 1);
      if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.length === 7
      ) {
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          // alert(
          //   language[this.props.currentLanguage].WrongFormat,
          // );
          this.openNotification('WrongFormat')
          this.setState({ notificationType: 'error' }, ()=> {
            // this.handleRemoveError(this.state.SelectedLine);
          });
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          // keyInFormula = this.state.Formula.KeyIn1.filter(
          //   item => item === 'CA1' || item === 'CC',
          // );
          // if (keyInFormula.length === 0) {
          //   keyInFormula = ['CA1','CC']
          // }
          keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
          if (keyInFormula.length === 0) {
            keyInFormula = ['A', 'C']
          } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
            keyInFormula = ['A']
          } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
            keyInFormula = ['C']
          } else {
            keyInFormula = ['A', 'C']
          }
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        !onlyWord.endsWith('**') &&
        onlyWord.length === 9
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          this.openNotification('WrongFormat')
          this.setState({ notificationType: 'error' }, ()=> {
            // this.handleRemoveError(this.state.SelectedLine);
          });
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = this.state.Formula.KeyIn1;
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        onlyWord.endsWith('**') &&
        onlyWord.length === 11
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          this.openNotification('WrongFormat')
          this.setState({ notificationType: 'error' }, ()=> {
            // this.handleRemoveError(this.state.SelectedLine);
          });
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = this.state.Formula.KeyIn2;
        }
      }
    } else if (RemoveDot.length === 5 && onlyWord.length === 5) {
      keyInFormula = ['5D'];
      WordQuantity = 1;
    } else if (RemoveDot.length === 6 && onlyWord.length === 6) {
      keyInFormula = ['6D'];
      WordQuantity = 1;
    }
    // 5d and 6D IBox and Box
    else if (
      RemoveDot.length === 5 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6
    ) {
      keyInFormula = ['5D'];
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = getPermutations.length;
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } else if (
      RemoveDot.length === 6 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7
    ) {
      keyInFormula = ['6D'];
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = getPermutations.length;
      Mode = (OneStar === 1) ? 'B' : 'IB';
    }
    else if (
      RemoveDot.length === 5 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7
    ) {
      keyInFormula = ['5D'];
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = getPermutations.length;
      Mode = (OneStar === 1) ? 'B' : 'IB';
    } else if (
      RemoveDot.length === 6 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8
    ) {
      keyInFormula = ['6D'];
      getPermutations = findPermutations(RemoveDot);
      WordQuantity = getPermutations.length;
      Mode = (OneStar === 1) ? 'B' : 'IB';
    }
    if (originalWord.startsWith('##')) {
      originalWord = originalWord.substring(2, originalWord.length);
      onlyWord = originalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      toProcessArray = originalWord.split('#');
      newLineWord = '##' + newLineWord;
      WordQuantity = 10;
      if (onlyWord.length === 4 && RemoveDot.length === 4) {
        Mode = '123{0-9}';
        keyInFormula = this.state.Formula.KeyIn1;
      } else if (onlyWord.length === 3 && RemoveDot.length === 3) {
        Mode = '123{0-9}';
        // keyInFormula = this.state.Formula.KeyIn1.filter(
        //   item => item === 'CA1' || item === 'CC',
        // );
        // if (keyInFormula.length === 0) {
        //   keyInFormula = ['CA1','CC']
        // }
        keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
        if (keyInFormula.length === 0) {
          keyInFormula = ['A', 'C']
        } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
          keyInFormula = ['A']
        } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
          keyInFormula = ['C']
        } else {
          keyInFormula = ['A', 'C']
        }
      } else if (onlyWord.length === 2 && RemoveDot.length === 2) {
        Mode = '123{0-9}';
        keyInFormula = ['PA', 'PF']
      } else if (onlyWord.length === 4 && RemoveDot.length === 2 && onlyWord.endsWith('**')) {
        Mode = '123{0-9}';
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF']
      } else if (onlyWord.length === 5 && RemoveDot.length === 3 && onlyWord.endsWith('**')) {
        Mode = '123{0-9}';
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF']
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 6 &&
        RemoveDot.length === 4
      ) {
        Mode = '123{0-9}';
        keyInFormula = this.state.Formula.KeyIn2;
      }
    } else if (originalWord.startsWith('#') && originalWord.split('#').length > 2) {
      originalWord = originalWord.substring(1, originalWord.length);
      onlyWord = originalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      toProcessArray = originalWord.split('#');
      newLineWord = '#' + newLineWord;
      WordQuantity = 10;
      if (onlyWord.length === 4 && RemoveDot.length === 4) {
        keyInFormula = this.state.Formula.KeyIn1;
        Mode = '{0-9}123';
      } else if (onlyWord.length === 3 && RemoveDot.length === 3) {
        // keyInFormula = this.state.Formula.KeyIn1.filter(
        //   item => item === 'CA1' || item === 'CC',
        // );
        // if (keyInFormula.length === 0) {
        //   keyInFormula = ['CA1','CC']
        // }
        keyInFormula = this.state.Formula.KeyIn1.filter((item) => item === 'A' || item === 'C')
        if (keyInFormula.length === 0) {
          keyInFormula = ['A', 'C']
        } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
          keyInFormula = ['A']
        } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
          keyInFormula = ['C']
        } else {
          keyInFormula = ['A', 'C']
        }
        Mode = '{0-9}123';
      } else if (onlyWord.length === 2 && RemoveDot.length === 2) {
        keyInFormula = ['PA', 'PF']
        Mode = '{0-9}123';
      } else if (onlyWord.length === 4 && RemoveDot.length === 2 && onlyWord.endsWith('**')) {
        Mode = '{0-9}123';
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF']
      } else if (onlyWord.length === 5 && RemoveDot.length === 3 && onlyWord.endsWith('**')) {
        Mode = '{0-9}123';
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF']
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 6 &&
        RemoveDot.length === 4
      ) {
        Mode = '{0-9}123';
        keyInFormula = this.state.Formula.KeyIn2;
      }
    }
    if (
      originalWord.includes('***#') &&
      !onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      let FilterFormula1 = [];
      for(let z = 0; z < toProcessArray.length; z += 1) {
        if(z === 0) {
          // ignore
        } else {
          FilterFormula1.push(this.state.Formula.KeyIn1[z - 1]);
        }
        if (toProcessArray[z].includes('***')) {
          break;
        }
      }
      let concatFormula = FilterFormula1.concat(this.state.Formula.KeyIn2);
      let filterArray = [];
      filterArray = concatFormula.filter(function(elem, pos) {
        return concatFormula.indexOf(elem) === pos;
      });
      toProcessArray = originalWord.replace('***', '').split('#');
      keyInFormula = filterArray;
    }
    if (Mode === 'IB' && Mode2 === '') {
      for (let j = 0; j < toProcessArray.length; j += 1) {
        newLineWord +=
          j === 0
            ? toProcessArray[j]
            : ` (IB)${keyInFormula[j - 1]} ${toProcessArray[j]}`;
        if (j !== 0) {
          BLineAmount += Number(toProcessArray[j]);
          CalculateLineAmount +=
            (this.state.MultiplyDivide === 'D') ? toProcessArray[j] * WordQuantity : toProcessArray[j] * numberOfPlatform * WordQuantity;
        }
      }
    }
    else if (Mode === 'formula') {
      let ArrayDisplay = [toProcessArray[0]]
      const getUserFormula = (this.state.getUserFormula === null) ? [] : this.state.getUserFormula
      let getformula = ''
      if (toProcessArray[1].endsWith('**')) {
        getformula = Object.values(getUserFormula).filter(function (el) {
          return el.Num === toProcessArray[1].replace('**', '')
        });
      } else {
        getformula = Object.values(getUserFormula).filter(function (el) {
          return el.Num === toProcessArray[1]
        });
      }
      if (getformula.length > 0) {
        const KeyinFormat = Object.keys(getformula[0])
        const Key3Formulas = KeyinFormat.filter(function(item) {
          return !['Num'].includes(item); 
        })
        Key3Formulas.forEach((item) => {
          ArrayDisplay.push(getformula[0][item])
        })
      }
      for (let j = 0; j < ArrayDisplay.length; j += 1) {
        newLineWord +=
          j === 0
            ? ArrayDisplay[j]
            : ` ${keyInFormula[j - 1]} ${ArrayDisplay[j]}`;
        if (j !== 0) {
          BLineAmount += Number(ArrayDisplay[j]);
          CalculateLineAmount +=
                (this.state.MultiplyDivide === 'D') ? ArrayDisplay[j] * WordQuantity : ArrayDisplay[j] * numberOfPlatform * WordQuantity;
        }
      }
    }  
    else if (Mode2 === 'XingJinFormula' && (Mode === 'B' || Mode === 'IB')) {
      const getUserFormula = this.state.getUserFormula
      if (getUserFormula !== null) {
        const getKeyinNumber = toProcessArray[1].split('')[0]
        const Totalsss = Number(getUserFormula[getKeyinNumber]['B']) + Number(getUserFormula[getKeyinNumber]['S']) + Number(getUserFormula[getKeyinNumber]['CA']) + Number(getUserFormula[getKeyinNumber]['CF'])
        CalculateLineAmount = (this.state.MultiplyDivide === 'D') ? Totalsss.toFixed(2) * WordQuantity : Totalsss.toFixed(2) * numberOfPlatform * WordQuantity
        newLineWord = `${toProcessArray[0]} B ${getUserFormula[getKeyinNumber]['B']} S ${getUserFormula[getKeyinNumber]['S']} A ${getUserFormula[getKeyinNumber]['CA']} C ${getUserFormula[getKeyinNumber]['CF']}`
      }
    }
    else {
      for (let j = 0; j < toProcessArray.length; j += 1) {
        newLineWord +=
          j === 0
            ? toProcessArray[j]
            : ` ${keyInFormula[j - 1]} ${toProcessArray[j]}`;
        if (j !== 0) {
          BLineAmount += Number(toProcessArray[j]);
          CalculateLineAmount +=
            (this.state.MultiplyDivide === 'D') ? toProcessArray[j] * WordQuantity : toProcessArray[j] * numberOfPlatform * WordQuantity;
        }
      }
    }
    return {newLineWord, BLineAmount, CalculateLineAmount, Mode, keyInFormula};
  }

  PressNumberFunction(Press) {
    const PressValidation = Press.replace(/[^0-9D.*#+-]/g, '')
    const PressNumber = PressValidation.replace(/[+]/g, '#')
    const lineData = this.state.lineData;
    // let onlyWord = lineData[this.state.SelectedLine].translatedWord.includes(' ');
    // let RemoveDot = onlyWord.replace(/([*])/g, '');
    if(PressValidation.includes('-') && PressValidation.length === 9) {
      const Start = PressValidation.split('-')[0]
      const End = PressValidation.substring(PressValidation.indexOf('-') + 1)
      if (Start > End) {
        const r = (window.confirm(`${language[this.props.currentLanguage].FormatError}`));
        if (r === true) {
          this.handleRemoveError(this.state.SelectedLine);
        } else {
          return null;
        }
      }
    }
    lineData[this.state.SelectedLine].originalWord =
            PressNumber;
    const DetectSymbolnegative = lineData[this.state.SelectedLine].translatedWord.match(/-/g)
    if ((DetectSymbolnegative || 0).length > 1){
      const r = (window.confirm(`${language[this.props.currentLanguage].FormatError}`));
      if (r === true) {
        this.handleRemoveError(this.state.SelectedLine);
      } else {
        return null;
      }
    }
    this.ProcessWords(lineData);
    let OriginalWordforStore = lineData[this.state.SelectedLine].originalWord
    let OriginalWord = lineData[this.state.SelectedLine].originalWord

    if (OriginalWord.startsWith('##')) {
      OriginalWord = OriginalWord.substring(2, OriginalWord.length);
    } else if (OriginalWord.startsWith('#')) {
      OriginalWord = OriginalWord.substring(1, OriginalWord.length);
    }

    let Back = OriginalWord.substring(OriginalWord.indexOf('#'))
    const splitBack = Back.split('')
    const splitOri = OriginalWord.split('#')[0].replace(/([*])/g, '')
    const front = OriginalWord.split('#')[0]

    const FilterBackValueWith = splitBack.filter(function(item) {
      return ['*'].includes(item); 
    })
    // if(front.includes('-') && front.length === 9) {
    //   const Start = front.split('-')[0]
    //   const End = front.substring(front.indexOf('-') + 1)
    //   if (Start > End) {
    //     const r = (window.confirm(`${language[this.props.currentLanguage].FormatError}`));
    //     if (r === true) {
    //       this.handleRemoveError(this.state.SelectedLine);
    //     } else {
    //       return null;
    //     }
    //   }
    // }
    // convert * to . on betting values
    const lineDatas = this.state.lineData;
    let FinalAns = '';
    let Word = lineDatas[this.state.SelectedLine].originalWord
    let CheckOri = lineDatas[this.state.SelectedLine].originalWord;
    if (CheckOri.startsWith('##')) {
      CheckOri = CheckOri.replace('##', '');
    } else if (CheckOri.startsWith('#')) {
      CheckOri = CheckOri.replace('#', '');
    }
    
    CheckOri = CheckOri.split('#');
    if (this.state.SelectedLine === 0) {
      //ignore This functions
    } else if (CheckOri.length > 1) {
      if(Word.startsWith('##')) {
        FinalAns = '##' + front.replace('.', '') + Back.replace(/([*])/g, '.')
        lineDatas[this.state.SelectedLine].originalWord = FinalAns;
        this.ProcessWords(lineDatas);
      } else if(Word.startsWith('#')) {
        FinalAns = '#' + front.replace('.', '') + Back.replace(/([*])/g, '.')
        lineDatas[this.state.SelectedLine].originalWord = FinalAns;
        this.ProcessWords(lineDatas);
      } else {
        if (Back.includes('.*') || Back.includes('..') || Back.includes('**')) {
          FinalAns = front + Back.replace(/([.])/g, '*')
        } else {
          FinalAns = front.replace('.', '') + Back.replace(/([*])/g, '.')
        }
        // FinalAns = front.replace('.', '') + Back.replace(/([*])/g, '.')
        lineDatas[this.state.SelectedLine].originalWord = FinalAns;
        this.ProcessWords(lineDatas);
        
      }
    }
    if(lineDatas[this.state.SelectedLine].originalWord.includes('...#')) {
      lineDatas[this.state.SelectedLine].originalWord = lineDatas[this.state.SelectedLine].originalWord.replace('...#', '***#')
      this.ProcessWords(lineDatas);
    }

    // validation for input length...
    if(OriginalWord.includes('#') === true) {
      if(this.state.SelectedLine !== 0) {
        const FilterBackvalue = splitBack.filter(function(item) {
          return ['#'].includes(item); 
        })
        if(splitOri.split('').length === 4 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 6) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 3 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 1 && front.endsWith('***') === true) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 3 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 6 && front.endsWith('**') === true) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 3 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 2 && front.endsWith('**') === false) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 2 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 1 && front.endsWith('***') === true) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 2 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 6 && front.endsWith('**') === true) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 2 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 2 && front.endsWith('**') === false) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if(splitOri.split('').length === 4 && FilterBackValueWith.length === 0 && FilterBackvalue.length > 1 && front.endsWith('***') === true) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if((splitOri.split('').length > 4 && !splitOri.includes('-')) && FilterBackvalue.length > 1) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        } else if((splitOri.split('').length > 4 && splitOri.includes('-')) && FilterBackvalue.length > 6) {
          this.openNotification('FormatError')
          this.setState({ notificationType: 'error' });
          lineData[this.state.SelectedLine].originalWord = OriginalWordforStore.substring(0, OriginalWordforStore.length - 1)
        }
        this.ProcessWords(lineData);
      }
    }
  }

  onPressIBOX() {
    const OneStar = this.state.OneStar;
    const lineData = this.state.lineData;
    const OriginalWord = lineData[this.state.SelectedLine].originalWord;
    let ProcessWord = '';
    let Mode = '';
    let onlyWord = '';
    let GetFormulaAmount = '';
    let RemoveDot = '';
    const StartWith = (OneStar === 1) ? OriginalWord.startsWith('**') : OriginalWord.startsWith('*');

    if (!OriginalWord.startsWith('D#') && !OriginalWord.includes('-')) {
      if (!StartWith) {
        onlyWord = OriginalWord.split('#')[0];
        RemoveDot = onlyWord.replace(/([*])/g, '');
        Mode = 'IB';
        GetFormulaAmount =
          OriginalWord.substring(OriginalWord.indexOf('#')) || '';
        if (OriginalWord.includes('#') && OriginalWord.startsWith('##')) {
          ProcessWord = (OneStar === 1) ? '**' + GetFormulaAmount.replace('##', '') : '*' + GetFormulaAmount.replace('##', '');
        } else if (OriginalWord.includes('#') && OriginalWord.startsWith('#')) {
          ProcessWord = (OneStar === 1) ? '**' + GetFormulaAmount.replace('#', '') : '*' + GetFormulaAmount.replace('#', '');
        } else if (
          OriginalWord.includes('#') &&
          !OriginalWord.startsWith('#') &&
          onlyWord.endsWith('***')
        ) {
          ProcessWord = (OneStar === 1) ? '**' + RemoveDot + '***' + GetFormulaAmount : '*' + RemoveDot + '***' + GetFormulaAmount;
        } else if (
          OriginalWord.includes('#') &&
          !OriginalWord.startsWith('#') &&
          onlyWord.endsWith('**')
        ) {
          ProcessWord = (OneStar === 1) ? '**' + RemoveDot + '**' + GetFormulaAmount : '*' + RemoveDot + '**' + GetFormulaAmount;
        } else if (
          (OriginalWord.startsWith('*') && !OriginalWord.includes('#')) ||
          !OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '**' + RemoveDot : '*' + RemoveDot;
        } else if (
          (RemoveDot.length < 2 || RemoveDot.length > 4) &&
          lineData[this.state.SelectedLine].Mode === ''
        ) {
          ProcessWord = OriginalWord;
          this.openNotification('FourDigits')
          this.setState({ notificationType: 'error' });
        } 
        //formula 1 
        else if(OriginalWord.substring(0, OriginalWord.length - 1).endsWith('*#') && lineData[this.state.SelectedLine].Mode === 'formula') { 
          ProcessWord = OriginalWord;
          this.openNotification('NotAllowOnFormular')
          this.setState({ notificationType: 'error' });
        } else {
          ProcessWord = (OneStar === 1) ? '**' + RemoveDot + GetFormulaAmount : '*' + RemoveDot + GetFormulaAmount;
        }
      } else {
        ProcessWord = (OneStar === 1) ? OriginalWord.replace('**', '') :  OriginalWord.replace('*', '');
      }
      const SelectedWord = document.getElementById('BetText')
      SelectedWord.focus()
      lineData[this.state.SelectedLine].originalWord = ProcessWord;
      lineData[this.state.SelectedLine].Mode = Mode;
      this.ProcessWords(lineData);
    }
  }

  onPressHit() {
    const OneStar = this.state.OneStar;
    const lineData = this.state.lineData;
    let OriginalWord = lineData[this.state.SelectedLine].originalWord;
    let ProcessWord = '';
    let onlyWord = '';
    let RemoveDot = '';
    let GetFormulaAmount = '';
    let Key2Sign = '';
    if (!OriginalWord.includes('-')) {
      if (OriginalWord.startsWith('##')) {
        OriginalWord = OriginalWord.substring(2, OriginalWord.length);
      } else if (OriginalWord.startsWith('#')) {
        OriginalWord = OriginalWord.substring(1, OriginalWord.length);
      }
      onlyWord = OriginalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      GetFormulaAmount = OriginalWord.substring(OriginalWord.indexOf('#'));
      if (onlyWord.endsWith('**')) {
        Key2Sign = '**';
      }
      if (!OriginalWord.startsWith('D#')) {
        if (
          (RemoveDot.length < 2 || RemoveDot.length > 4) &&
          lineData[this.state.SelectedLine].Mode === ''
        ) {
          ProcessWord = OriginalWord;
          this.openNotification('FourDigits')
          this.setState({ notificationType: 'error' });
        }
        //Formula 1
        else if (OriginalWord.substring(0, OriginalWord.length - 1).endsWith('*#') && lineData[this.state.SelectedLine].Mode === 'formula') {
          ProcessWord = OriginalWord;
          this.openNotification('NotAllowOnFormular')
          this.setState({ notificationType: 'error' });
        } 
        else if (
          OriginalWord.includes('#') &&
          !OriginalWord.startsWith('#') &&
          onlyWord.endsWith('***') && lineData[this.state.SelectedLine].Mode === ''
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + '***' + GetFormulaAmount : '**' + RemoveDot + '***' + GetFormulaAmount;
        } 
        else if (
          lineData[this.state.SelectedLine].Mode === '' &&
          RemoveDot.length === 3 &&
          !OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + '#' : '**' + RemoveDot + Key2Sign + '#';
        } 
        // 2D
        else if (
          lineData[this.state.SelectedLine].Mode === '' &&
          RemoveDot.length === 2 &&
          OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + GetFormulaAmount : '**' + RemoveDot + Key2Sign + GetFormulaAmount;
        }
        else if (
          lineData[this.state.SelectedLine].Mode === '' &&
          RemoveDot.length === 3 &&
          OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + GetFormulaAmount : '**' + RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '' &&
          RemoveDot.length === 4 &&
          !OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + '#' : '**' + RemoveDot + Key2Sign + '#';
        } else if (
          lineData[this.state.SelectedLine].Mode === '' &&
          RemoveDot.length === 4 &&
          OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + GetFormulaAmount : '**' + RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '' &&
          RemoveDot.length === 4 &&
          !OriginalWord.includes('#')
        ) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + '#' : '**' + RemoveDot + Key2Sign + '#';
        } else if (lineData[this.state.SelectedLine].Mode === 'IB' && !onlyWord.endsWith('***')) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + Key2Sign + GetFormulaAmount : '**' + RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (lineData[this.state.SelectedLine].Mode === 'IB' && onlyWord.endsWith('***')) {
          ProcessWord = (OneStar === 1) ? '*' + RemoveDot + '***' + GetFormulaAmount : '**' + RemoveDot + '***' + GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === 'B' &&
          (RemoveDot.length === 2 || RemoveDot.length === 3 || RemoveDot.length === 4) && onlyWord.endsWith('***')
        ) {
          ProcessWord = RemoveDot + '***' + GetFormulaAmount;
        } else if (lineData[this.state.SelectedLine].Mode === 'B' && RemoveDot.length > 2 && !onlyWord.endsWith('***')) {
          ProcessWord = '#' + RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (lineData[this.state.SelectedLine].Mode === '{0-9}123') {
          ProcessWord = '##' + RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
          RemoveDot.length === 3
        ) {
          ProcessWord =
            RemoveDot.substring(0, 2) +
            '*' +
            RemoveDot[2] +
            Key2Sign +
            GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
          RemoveDot.length === 4
        ) {
          ProcessWord =
            RemoveDot.substring(0, 3) +
            '*' +
            RemoveDot[3] +
            Key2Sign +
            GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '{123}4' &&
          RemoveDot.length === 3
        ) {
          ProcessWord =
            RemoveDot[0] +
            '*' +
            RemoveDot.substring(1, 3) +
            Key2Sign +
            GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '{123}4' &&
          RemoveDot.length === 4
        ) {
          ProcessWord =
            RemoveDot[0] +
            '*' +
            RemoveDot.substring(1, 4) +
            Key2Sign +
            GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '1{234}' &&
          RemoveDot.length === 3
        ) {
          ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === '1{234}' &&
          RemoveDot.length === 4
        ) {
          ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
        } else if (
          lineData[this.state.SelectedLine].Mode === 'B' &&
          RemoveDot.length === 2
        ) {
          ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
        }
        const SelectedWord = document.getElementById('BetText')
        SelectedWord.focus()
        lineData[this.state.SelectedLine].originalWord = ProcessWord;
        this.ProcessWords(lineData);
      }
    }
  }

  ReverseNumFunction() {
    const lineData = this.state.lineData || [];
    let OriginalWord = lineData[this.state.SelectedLine].originalWord;
    const Mode = lineData[this.state.SelectedLine].Mode;
    let onlyWord = '';
    let GetFormulaAmount = '';
    let RemoveDot = '';
    let Key2Sign = '';
    let ReverseWord = '';
    if (this.state.SelectedLine !== 0) {
      if (OriginalWord.startsWith('##')) {
        OriginalWord = OriginalWord.substring(2, OriginalWord.length);
      } else if (OriginalWord.startsWith('#')) {
        OriginalWord = OriginalWord.substring(1, OriginalWord.length);
      }
      onlyWord = OriginalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      GetFormulaAmount = OriginalWord.substring(OriginalWord.indexOf('#'));
      if (RemoveDot.length === 4 && GetFormulaAmount.includes('#')) {
        if (onlyWord.endsWith('**')) {
          Key2Sign = '**';
        }
        ReverseWord = RemoveDot.split('');
        ReverseWord = ReverseWord.slice()
          .reverse()
          .join()
          .replace(/([,])/g, '');
        this.NextLineButtonFunction({ key: 'Enter' }, 0, 'Reverse');
        if (Mode === 'IB') {
          ReverseWord = '**' + ReverseWord;
        } else if (Mode === 'B') {
          ReverseWord = '*' + ReverseWord;
        } else if (Mode === '{0-9}123') {
          ReverseWord = '#' + ReverseWord;
        } else if (Mode === '123{0-9}') {
          ReverseWord = '##' + ReverseWord;
        } else if (Mode === '{123}4') {
          ReverseWord = ReverseWord.substring(0, 3) + '*' + ReverseWord[3];
        } else if (Mode === '1{234}') {
          ReverseWord = ReverseWord[0] + '*' + ReverseWord.substring(1, 4);
        }
        lineData[lineData.length - 1].originalWord =
                    ReverseWord + Key2Sign + GetFormulaAmount;

      } else {
        this.openNotification('Only4DWithAmount')
        this.setState({ notificationType: 'error' });
      }
      this.ProcessWords(lineData);
    } else {
      this.openNotification('Only4DWithAmount')
      this.setState({ notificationType: 'error' });
    }
  }

  SelectLineFunction(idx) {
    let Line = idx.Line;
    let SelectLine = this.state.Line;
    if (Line === 0) {
      this.setState({ SelectedLine: Line, PressCount: this.state.PressCount + 1 }, () => {
        if (this.state.PressCount === 2) {
          this.setModalVisible(true)
          this.setState({ PressCount: 0 })
        }},
      );
    } else {
      this.setState({
        SelectedLine: Line,
        PressCount: 0,
      },() => this.ProcessWords(SelectLine),
      );
    }
  }

  onpressLongTerm(e) {
    const toProcessDateData = this.state.ModalDate;

    for(let i = 0; i < toProcessDateData.DateLoop.length; i++) {
      if(toProcessDateData.DateLoop[i].CheckMark === true) {
        toProcessDateData.DateLoop[i].CheckMark = false
      }
    }
    const lineData = this.state.lineData;
    lineData[0].originalWord = 'D#*';
    this.ProcessWords(lineData);
    this.setState({ modalShow: false, ModalDate: toProcessDateData });
    this.NextLineButtonFunction(e);
  }

  NextLineButtonFunction(e, idx, Hint) {
    if (e.key === 'Enter' || e.code === 'ArrowDown') {
      let addOneNewLine = this.state.lineData;
      if (
        this.state.lineData.length <= this.state.SelectedLine + 1 ||
                Hint === 'Reverse'
      ) {
        addOneNewLine.push({
          originalWord: '',
          translatedWord: '',
          CalculateLineAmount: '',
        });
        this.setState({ PressCount: 0 })
      }
      if (Hint === 'Nextline') {
        if(this.state.SelectedLine === 0) {
          if (addOneNewLine[0].originalWord.startsWith('D#1*')) {
            this.CallRebuyAPI(addOneNewLine[0].originalWord);
          }
        }
        const JinNan = this.state.JinNanCheck
        let ErrorDetect = false
        const lineDataWord = this.state.lineData[this.state.SelectedLine].originalWord
        let OriginalWord = this.state.lineData[this.state.SelectedLine].originalWord
        if (OriginalWord.startsWith('##')) {
          OriginalWord = OriginalWord.substring(2, OriginalWord.length);
        }
        else if (OriginalWord.startsWith('#')) {
          OriginalWord = OriginalWord.substring(1, OriginalWord.length);
        }
        let onlyWord = OriginalWord.split('#')[0];
        let RemoveDot = onlyWord.replace(/([*])/g, '');
        const Back = OriginalWord.substring(OriginalWord.indexOf('#'))
        const splitBack = Back.split('');
        const FilterBackvalue = splitBack.filter(function(item) {
          return ['#'].includes(item); 
        })
        if(!OriginalWord.includes('D')) {
          // this.state.JinNanCheck === 1 &&
          // thisLineWord.split('#').length === 2
          //Formula 1
          // if(OriginalWord.substring(0, OriginalWord.length - 1).endsWith('*#') && this.state.lineData[idx].Mode === 'formula') {
          // }
          if (onlyWord.endsWith('***') && (RemoveDot.length === 4 || RemoveDot.length === 3 || RemoveDot.length === 2) && FilterBackvalue.length === 1) {
            addOneNewLine[this.state.SelectedLine].originalWord =
                      lineDataWord + '#' + splitBack[1] + '#' + splitBack[1] + '#' + splitBack[1] + '#' + splitBack[1];
          }
          if (JinNan === 1) {
            if(lineDataWord.split('#').length !== 2 && !Back.includes('#') && FilterBackvalue.length === 0) {
              let AmountCopy = '';
              for (var a = this.state.SelectedLine; a >= 0; a--) {
                if (addOneNewLine[a].BLineAmount > 0) {
                  let AmountOriWord  = addOneNewLine[a].originalWord
                  let FrontOriword =  AmountOriWord.split('#')[0];
                  if (AmountOriWord.startsWith('##')) {
                    AmountOriWord = AmountOriWord.replace('##', '');
                    AmountCopy = AmountOriWord.substring(AmountOriWord.indexOf('#') + 1);
                  } else if(AmountOriWord.startsWith('#')) {
                    AmountOriWord = AmountOriWord.replace('#', '');
                    AmountCopy = AmountOriWord.substring(AmountOriWord.indexOf('#') + 1);
                  } else {
                    AmountCopy = AmountOriWord.substring(AmountOriWord.indexOf('#') + 1);
                  }
                  const removejin = lineDataWord.replace(/([#])/g, '');
                  const onlyWord = removejin.replace(/([*])/g, '');
                  const FormulaWord = `${removejin}*`

                  const Amount = AmountCopy.split('#').filter(item => item !== '');
                  if((onlyWord.length === 3 || onlyWord.length === 2) && !removejin.endsWith('***')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    `${(FrontOriword.endsWith('**')) ? `${lineDataWord + '**'}`: lineDataWord}#${Amount[0]}${(Amount[1] !== undefined) ? `#${Amount[1]}` : ''}`
                    break; 
                  } 
                  if(onlyWord.length > 4 && !onlyWord.includes('-')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    `${lineDataWord}#${(Amount[0] !== undefined) ? `${Amount[0]}` : ''}`
                    break; 
                  } 
                  if(onlyWord.length === 4 && !removejin.endsWith('***')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    (FrontOriword.endsWith('*') && FrontOriword.length === 5) ? FormulaWord + '#' + AmountCopy : (FrontOriword.endsWith('**') && FrontOriword.length === 6) ? lineDataWord + '**#' + AmountCopy : lineDataWord + '#' + AmountCopy
                    break; 
                  }
                  if(onlyWord.length === 9 && onlyWord.includes('-')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                      lineDataWord + '#' + AmountCopy;
                    break; 
                  }
                  if((onlyWord.length === 3 || onlyWord.length === 2 || onlyWord.length === 4) && removejin.endsWith('***')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    `${(FrontOriword.endsWith('**')) ? `${lineDataWord + '**#'}`: lineDataWord}#${Amount[0]}#${Amount[0]}#${Amount[0]}#${Amount[0]}#${Amount[0]}`
                    break; 
                  } 
                }
              }
            }
          } else {
            if(Back.startsWith('#') && Back.endsWith('#') && FilterBackvalue.length === 1) {
              let AmountCopy = '';
              for (var i = this.state.SelectedLine; i >= 0; i--) {
                if (addOneNewLine[i].BLineAmount > 0) {
                  let AmountOriWord  = addOneNewLine[i].originalWord
                  let FrontOriword =  AmountOriWord.split('#')[0];
                  if (AmountOriWord.startsWith('##')) {
                    AmountOriWord = AmountOriWord.replace('##', '');
                    AmountCopy = AmountOriWord.substring(AmountOriWord.indexOf('#') + 1);
                  } else if (AmountOriWord.startsWith('#')) {
                    AmountOriWord = AmountOriWord.replace('#', '');
                    AmountCopy = AmountOriWord.substring(
                      AmountOriWord.indexOf('#') + 1,
                    );
                  } else {
                    AmountCopy = AmountOriWord.substring(AmountOriWord.indexOf('#') + 1);
                  }
                  const removejin = lineDataWord.replace(/([#])/g, '');
                  const onlyWord = removejin.replace(/([*])/g, '');
                  const FormulaWord = `${removejin}*#`

                  const Amount = AmountCopy.split('#').filter(item => item !== '');
                  if((onlyWord.length === 3 || onlyWord.length === 2) && !removejin.endsWith('***')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    `${(FrontOriword.endsWith('**')) ? `${lineDataWord.substring(0, lineDataWord.length - 1) + '**#'}`: lineDataWord}${Amount[0]}${(Amount[1] !== undefined) ? `#${Amount[1]}` : ''}`
                    break; 
                  } 
                  if(onlyWord.length > 4 && !onlyWord.includes('-')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    `${lineDataWord}${(Amount[0] !== undefined) ? `${Amount[0]}` : ''}`
                    break; 
                  } 
                  if(onlyWord.length === 4 && !removejin.endsWith('***')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    (FrontOriword.endsWith('*') && FrontOriword.length === 5) ? FormulaWord + AmountCopy : (FrontOriword.endsWith('**') && FrontOriword.length === 6)? lineDataWord.substring(0, lineDataWord.length - 1) + '**#' + AmountCopy : lineDataWord + AmountCopy
                    break; 
                  }
                  if(onlyWord.length === 9 && onlyWord.includes('-')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                      lineDataWord + AmountCopy;
                    break;
                  }
                  if((onlyWord.length === 3 || onlyWord.length === 2 || onlyWord.length === 4) && removejin.endsWith('***')) {
                    addOneNewLine[this.state.SelectedLine].originalWord =
                    `${(FrontOriword.endsWith('**')) ? `${lineDataWord.substring(0, lineDataWord.length - 1) + '**#'}`: lineDataWord}${Amount[0]}#${Amount[0]}#${Amount[0]}#${Amount[0]}#${Amount[0]}`
                    break; 
                  }
                }
              }
            } else {
              //ignore
            }
          }
        }
        this.ProcessWords(addOneNewLine)

        if(JinNan === 0) {
          if(!OriginalWord.includes('#') && (!lineDataWord.startsWith('#'))) {
            const SplitWords =  OriginalWord.split('')
            let AfterFilter = []
            // if(SplitWords.includes('9')) {
            //   this.openNotification('ErrorPlatform')
            //   this.setState({ notificationType: 'error' });
            // }
            AfterFilter = SplitWords.filter(function(item, pos) {
              return SplitWords.indexOf(item) === pos && item !== '0';
            })
            addOneNewLine[this.state.SelectedLine].originalWord =  AfterFilter.join('') 
          }
        } else {
          if(lineDataWord.startsWith('#') && lineDataWord.split('#').length === 2) {
            const SplitWords = lineDataWord.split('')
            let AfterFilter = []
            // if(SplitWords.includes('9')) {
            //   this.openNotification('ErrorPlatform')
            //   this.setState({ notificationType: 'error' });
            // }
            AfterFilter = SplitWords.filter(function(item, pos) {
              return SplitWords.indexOf(item) === pos && item !== '0';
            })
            addOneNewLine[this.state.SelectedLine].originalWord =  AfterFilter.join('') 
          }
        }

        if(ErrorDetect !== true) {
          if (this.state.WordLine === this.state.SelectedLine) {
            this.setState(
              {
                SelectedLine: this.state.SelectedLine + 1,
                WordLine: this.state.WordLine + 1,
                PressCount: 0,
              },
              () => this.ProcessWords(addOneNewLine),
            );
          } else {
            this.setState(
              {
                SelectedLine: this.state.SelectedLine + 1,
                PressCount: 0,
              },
              () => this.ProcessWords(addOneNewLine),
            );
          }
        }
      }

      else if (Hint === 'Reverse') {
        this.setState(
          {
            WordLine: this.state.WordLine + 1,
            SelectedLine: this.state.WordLine + 1,
            PressCount: 0,
          },
          () => this.ProcessWords(addOneNewLine),
        );
      }
    }
    if(e.code === 'ArrowUp') {
      if (this.state.SelectedLine > 0) {
        this.setState({
          SelectedLine: this.state.SelectedLine - 1,
          PressCount: 0,
        }, ()=> {
          setTimeout(() => {
            const SelectedWord = document.getElementById('BetText')
            const length = SelectedWord.value.length
            SelectedWord.focus()
            SelectedWord.setSelectionRange(length, length)
          }, 20);
          // SelectedWord.selectionStart = SelectedWord.selectionEnd = length;
          
          // SelectedWord.focus()
          // const length = SelectedWord.value.length
          // SelectedWord.setSelectionRange(length, length)
        });
      }
    }
    // if(e.code === 'ArrowDown') {
    //   let addOneNewLine = this.state.lineData;
    //   if (
    //     this.state.lineData.length <= this.state.SelectedLine + 1 ||
    //             Hint === 'Reverse'
    //   ) {
    //     addOneNewLine.push({
    //       originalWord: '',
    //       translatedWord: '',
    //       CalculateLineAmount: '',
    //     });
    //     this.setState({ PressCount: 0 })
    //   }
    //   if (Hint === 'Nextline') {
    //     if (this.state.WordLine === this.state.SelectedLine) {
    //       this.setState(
    //         {
    //           SelectedLine: this.state.SelectedLine + 1,
    //           WordLine: this.state.WordLine + 1,
    //           PressCount: 0,
    //         },
    //         () => this.ProcessWords(addOneNewLine),
    //       );
    //     } else {
    //       this.setState(
    //         {
    //           SelectedLine: this.state.SelectedLine + 1,
    //           PressCount: 0,
    //         },
    //         () => this.ProcessWords(addOneNewLine),
    //       );
    //     }
    //   } else if (Hint === 'Reverse') {
    //     this.setState(
    //       {
    //         WordLine: this.state.WordLine + 1,
    //         SelectedLine: this.state.WordLine + 1,
    //         PressCount: 0,
    //       },
    //       () => this.ProcessWords(addOneNewLine),
    //     );
    //   }
    // }
    if(e.code === 'Space') {
      e.preventDefault();
      this.ConfirmSaveBet();
    }
    if(e.code === 'KeyQ') {
      e.preventDefault();
      this.CleanData();
    }
    if(e.code === 'KeyD') {
      e.preventDefault();
      this.onPressHit();
    }
    // if(e.code === 'NumpadSubtract') {
    //   e.preventDefault();
    //   this.handleRemoveNumbersChange(this.state.SelectedLine)
    // }
    if(e.code === 'KeyL') {
      e.preventDefault();
      this.ReverseNumFunction()
    }
    if(e.code === 'KeyI') {
      e.preventDefault();
      this.onPressIBOX()
    }
  }

  async CallRebuyAPI(FirstData) {
    const userID = this.state.userID;
    let OriText = ''
    const getOriginalText = await getOrderOriginalText(userID, FirstData.split('*')[1], this.props.username,this.props.token);
    if (getOriginalText.error === 'Invalid UserOrderID!') {
      this.openNotification('NoDataFound')
      this.setState({ notificationType: 'error' });
      this.CleanData()
    } else if(getOriginalText.error === 'Invalid credentials!') {
      this.openNotification('NoDataFound')
      this.setState({ notificationType: 'error' });
      this.CleanData()
    } else {
      OriText = getOriginalText.originalText
    }
    let LineData = [{
      originalWord: '',
      translatedWord: '',
      Mode: '',
    }]

    if(OriText === '') {
      this.openNotification('NoDataFound')
      this.setState({ notificationType: 'error' });
    } else {
      let OriginalText = OriText.split('\n');
      let AmountCopy = '';
      for (let i = 0; i < OriginalText.length; i++) {
        if(!OriginalText[i].includes('D#')) {
          let OriWord = OriginalText[i]
          if (OriWord.startsWith('##')) {
            OriWord = OriWord.replace('##', '');
          } else if (OriWord.startsWith('#')) {
            OriWord = OriWord.replace('#', '');
          }
          const OriWordArray = OriWord.split('#');
          if (OriWordArray.length > 2 && OriWordArray[0] !== '') {
            AmountCopy = OriginalText[i].substring(
              OriginalText[i].indexOf('#') + 1,
            );
            OriWord = OriginalText[i];
          } else if (OriWordArray.length === 2 && OriWordArray[1] === '') {
            OriWord = OriginalText[i] + AmountCopy;
          } else {
            OriWord = OriginalText[i];
          }
          LineData.push({
            originalWord: OriWord,
            translatedWord: '',
            Mode: '',
          });
        }
      }
      LineData[0].originalWord = FirstData
      this.ProcessWords(LineData)
      this.convertPlatformbyJinnan()
    }
  }

  async handleRemoveNumbersChange(idx) {
    if (idx === 0) {
      this.openNotification('DeletedLine')
      this.setState({ notificationType: 'error', PressCount: 0 });
    } else {
      const r = (window.confirm(language[this.props.currentLanguage].ConfirmDeletedLine));
      if (r === true) {
        var lineData = this.state.lineData;
        var toRemove = idx;
        var index = lineData.indexOf(toRemove);
        if (index > -1) {
          lineData.splice(index, 1);
        }
        this.setState({ lineData: this.state.lineData.filter((s, sidx) => idx !== sidx) }, ()=> {
          this.ProcessWords(this.state.lineData)
          const SelectedWord = document.getElementById('BetText')
          if(SelectedWord !== null) {
            SelectedWord.focus()
          } 
          // else {
          //   this.NextLineButtonFunction({ key: 'Enter' }, 0, 'Nextline');
          // }
        });
      } else {
        return null;
      }
    }
  };

  async handleRemoveError(idx) {
    let addOneNewLine = this.state.lineData;
    if (idx === 0) {
      this.openNotification('DeletedLine')
      this.setState({ notificationType: 'error', PressCount: 0 });
    } else {
      var lineData = this.state.lineData;
      var toRemove = idx;
      var index = lineData.indexOf(toRemove);
      if (index > -1) {
        lineData.splice(index, 1);
      }
      this.setState({ lineData: this.state.lineData.filter((s, sidx) => idx !== sidx) }, () => {
        lineData[this.state.SelectedLine].originalWord = "";
        lineData[this.state.SelectedLine].Mode = "";
        lineData[this.state.SelectedLine].translatedWord = "";
        lineData[this.state.SelectedLine].CalculateLineAmount = "";
        this.ProcessWords(addOneNewLine)
      });
    }
  };

  async CleanData() {
    // const r = (window.confirm(language[this.props.currentLanguage].ClearAllData));
    // if (r === true) {
    this.setState({
      lineData: [
        {
          originalWord: 'D#',
          translatedWord: 'D#',
          FinalOriginalWord: '',
        },
      ],
      OverallAmount: 0,
      SelectedLine: 0,
    })
    // } else {
    //     return null;
    // }
  }

  onpressPeriod(Num) {
    const lineData = this.state.lineData;
    lineData[0].originalWord = 'D#' + Num;
    this.ProcessWords(lineData);
    this.setState({modalShow: false});
    this.NextLineButtonFunction({ key: 'Enter' }, 0, 'Nextline')
  }

  openNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(Words !== undefined && Words !== '') ? `${language[this.props.currentLanguage].LeftCashWallet}:${Words || ''}` : ``}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  CopyToC(TextResult) {
    const DownloadWord = TextResult.split('<red>').join('</red>').split('</red>');
    navigator.clipboard.writeText(DownloadWord.join(''))
    this.openNotification('SuccessCopied')
    this.setState({ notificationType: true });
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }

  render() {
    return (
      // <div className="App backgroundImage4" style={{ paddingTop: 25, minHeight: 1000, overflowX: 'auto' }}>
      // <div className="App" style={{ paddingTop: 25, minHeight: 1000, backgroundColor: 'lightsteelblue' }}>
      <div className='StartBackground'> 
        <Header />
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
            show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="row TitleBettingVersion" style={{ marginLeft: 50, marginRight: 50 }}>
          <div className="dcc jackpotborderRedBet" style={{ justifyContent: 'space-between', width: '100%'}}>
            <div style={{width: '33.3%'}}></div>
            <div style={{width: '33.3%'}} className='dcc'><FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }} /> {language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].Red})</div>
            <div style={{ width: '33.3%', textAlign: 'right', paddingRight: 10, fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].Balance} : $ ${this.state.Balance}`}</div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
            marginBottom: 0,
          }}>
          <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
          {/* {isMobile && 
            <div className="TitleDate">{language[this.props.currentLanguage].Balance}: {`$ ${this.state.Balance}`}</div>
          } */}
          {/* {(isMobile === false) && 
          } */}
          <div className="dcc" style={{ marginRight: 10 }}>
            <div className="TitleUsername"> ID - {this.state.username} </div>
          </div>
        </div>
        {/* <div className="dcc TextFontSize" style={{ marginBottom: 10 }}>
          <div style={{ width: (isMobile) ? '80%' :'35%'}}>
            <Select
              isSearchable={this.state.optionsUsername.length === 0 ? false : true}
              options={this.state.optionsUsername}
              value={this.state.SelectedUser}
              onChange={(e) => this.SelectedUser(e)}
            >
            </Select>
          </div>
        </div> */}
        <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
          <div style={{ margin: '0px 10px',  fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchAccount}</div>
          <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
          <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
          <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getDownline()}>{'Self'}</Button>
        </div>
        <Modal transparent={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShow}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 500 }}>
            <GrFormClose
              className='ButtonCloseModal'
              onClick={() => {
                this.setState({ modalShow: false })
              }}
              size="35px"
              color="#000"
            />
            <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
              <div style={{ flexDirection: 'column' }}>
                <div className="dcc" style={{ fontWeight: 'bold', fontSize: '20px' }}>{language[this.props.currentLanguage].Date}</div>
                <div className="dcc" style={{ width: '100%', flexWrap: 'wrap', flexDirection: 'column' }}>
                  <div className="dcc" style={{ marginBottom: 15, flexDirection: 'column', width: '100%' }}>
                    {(this.state.ModalDate.DateLoop || []).map((item, idx) => {
                      return (
                        <button
                          className="DateSelectButton DateModalButton" key={idx}
                          style={{ width: '100%' }}
                          onClick={() => this.SelectDateFunction({ Index: idx })}>
                          <div className="dcc row" style={{ justifyContent: 'space-evenly' }}>
                            <div>{item.Date}</div>
                            {item.CheckMark && (
                              <div>
                                <img src={CheckMark}
                                  alt="Logo"
                                  style={{ width: 40, height: 40 }}
                                />
                              </div>
                            )}
                          </div>
                        </button>
                      );
                    })}
                  </div>
                  <div className="column" style={{ textAlign: 'center'}}>
                    <div style={{ marginBottom: 5 }}>
                      <button style={{ width: 200, marginRight: 30 }}
                        disabled={this.state.disabled}
                        onClick={(e) => this.onpressLongTerm(e)}
                        className="btn my-cusomized-button button2 BoxversionDateButton" >
                        {language[this.props.currentLanguage].Auto}
                      </button>
                      <button style={{ width: 200 }}
                        disabled={this.state.disabled}
                        onClick={() => this.onPressConfirmSelectDate()}
                        className="btn my-cusomized-button button2 BoxversionDateButton" >
                        {language[this.props.currentLanguage].Confirm}
                      </button>
                    </div>
                    <div>
                      <button style={{ width: 200, marginRight: 30 }}
                        disabled={this.state.disabled}
                        onClick={(e) => this.onpressPeriod(2)}
                        className="btn my-cusomized-button button2 BoxversionDateButton" >
                        {language[this.props.currentLanguage].secondPeriod}
                      </button>
                      <button style={{ width: 200 }}
                        disabled={this.state.disabled}
                        onClick={() => this.onpressPeriod(3)}
                        className="btn my-cusomized-button button2 BoxversionDateButton" >
                        {language[this.props.currentLanguage].ThreePeriod}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* {`dcc mobileHeaderMo1 ${isMobile && 'mobileHeaderMo'}`} */}
        <div className={`row`} style={{ width: '100%', marginBottom: 20, justifyContent: 'center', textAlign: 'none'}}>
          <div className={ (this.state.ShowReceipt === true || this.state.loading === true) ?  `` : `dcc`}
            style={{width: (this.state.ShowReceipt === true || this.state.loading === true) ? '50%' : '100%'}}>
            <div className="column" style={{ backgroundColor: 'white', width: (this.state.ShowReceipt === true || this.state.loading === true) ? '100%' : '50%', borderRadius: 5, border: `4px solid white`, marginLeft: 0, alignContent: 'center' }}>
              <div className="dcc" style={{ width: '100%', flexDirection: 'column' }}>
                <div className="dcc row" style={{ padding: 15, fontWeight: 'bold', fontSize: '20px', width: '100%', justifyContent: 'space-evenly'}}>
                  <div>
                    <button style={{ backgroundColor: this.state.ButtonClear, width: 200, padding: 'auto' }}
                      disabled={this.state.disabled}
                      onMouseEnter={() => {
                        this.setState({ ButtonClear: '#FF7000' });
                      }}
                      onMouseLeave={() => {
                        this.setState({ ButtonClear: '#EE3E13' });
                      }}
                      onClick={() => this.CleanData()}
                      className="btn my-cusomized-button button2 bettingFunctionsButton TextFontSize">
                      {language[this.props.currentLanguage].ClearAll} (Q)
                    </button>
                  </div>
                  <div className='TextFontSize'>{`${language[this.props.currentLanguage].Bet} (${this.state.SelectedUser.label})`}</div>
                  <div>
                    <button style={{ backgroundColor: this.state.ButtonSend, width: 200 }}
                      disabled={this.state.disabled}
                      onMouseEnter={() => {
                        this.setState({ ButtonSend: '#00AFE2' });
                      }}
                      onMouseLeave={() => {
                        this.setState({ ButtonSend: '#3C96F4' });
                      }}
                      onClick={() => this.ConfirmSaveBet()}
                      className="btn my-cusomized-button button2 bettingFunctionsButton TextFontSize">
                      {language[this.props.currentLanguage].ConfirmTicket} (SpaceBar)
                    </button>
                  </div>
                </div>
                <div style={{ overflowY: 'scroll', height: 400, backgroundColor: '#EFEFEF', width: '100%' }}>
                  <div style={{ marginBottom: 10 }}></div>
                  {this.state.lineData.map((item, idx) => {
                    return (
                      <div className="dcc column" style={{ width: '100%' }} key={idx}>
                        <div style={{ fontWeight: 'bold', paddingRight: 5, width: '10%' }}>{idx + 1}</div>
                        <div className="dcc" style={{ width: '80%' }}>
                          {this.state.SelectedLine === idx ?
                            <Form.Control
                              autoFocus={true}
                              key={idx}
                              name={`field-${idx}`}
                              value={`${item.originalWord}`}
                              id={`BetText`}
                              style={{
                                width: '100%',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: '#303030',
                              }}
                              onClick={() => this.SelectLineFunction({ Line: idx })}
                              type="text"
                              onKeyDown={(e) => this.NextLineButtonFunction(e, idx, 'Nextline')}
                              onChange={(e) => this.PressNumberFunction(e.target.value)}
                              onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                            />
                            :
                            <button
                              key={idx}
                              name={`field-${idx}`}
                              type="button"
                              onClick={() => this.SelectLineFunction({ Line: idx })}
                              onKeyDown={(e) => this.NextLineButtonFunction(e, idx, 'Nextline')}
                              onChange={(e) => this.PressNumberFunction(e.target.value)}
                              className="form-control BettingResultButtonCSS">
                              <div className='BettingResultButtonViews'>
                                <div style={{ color: '#B34C5E'}}>{item.Mode !== '' ? item.Mode : ' '}</div>
                                <div className='BettingResultMiddleR'>{item.translatedWord.split(" ").map((x, ind) => (
                                  <div key={ind} style={{
                                    color: (ind === 0) && (/^[BTKSMDW8P9]+$/i.test(x)) && (x.includes('888') === false) ? 'green' : ((ind === 0) && (x.startsWith('D#') === true)) ? 'purple' : (ind === 0) ? 'black' : (ind % 2) ? 'blue' : (item.translatedWord === '<ERR>') ? 'red' : 'red',
                                    fontSize: (ind % 2) ? '14px' : (ind === 0) ? '17px' : '17px', padding: 1.5,
                                  }}>{`${x} `}
                                  </div>
                                ))}
                                </div>
                                <div style={{ color: '#99402C', fontSize: '13px' }}>{item.CalculateLineAmount !== '' ? item.CalculateLineAmount : ''}</div>
                              </div>
                            </button>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="dcc">
                <div className="DisplayBettingFormat dcc MenubarTitle">{language[this.props.currentLanguage].CurrentFormat} : {this.state.DisplayFormula}</div>
              </div>
              <div className="dcc" style={{ marginBottom: 20, fontWeight: 'bold', marginTop: 20, flexDirection: 'row' }}>
                <div style={{ paddingRight: 10 }}>
                  <button
                    type="button"
                    onClick={() => this.onPressIBOX()}
                    className="HitButtonRed button2 BettingTypebutton TextFontSize">
                    {language[this.props.currentLanguage].IBox}
                  </button>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <button
                    type="button"
                    onClick={() => this.onPressHit()}
                    className="HitButtonRed button2 BettingTypebutton TextFontSize">
                                    打字
                  </button>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <button
                    type="button"
                    onClick={this.ReverseNumFunction}
                    className="HitButtonRed button2 BettingTypebutton TextFontSize">
                                    来回
                  </button>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <button
                    type="button"
                    onClick={() => this.handleRemoveNumbersChange(this.state.SelectedLine)}
                    className="HitButtonRed button2 BettingTypebutton TextFontSize">
                                   删去
                  </button>
                </div>
              </div>
              <div style={{ marginBottom: 20, fontWeight: 'bold', marginTop: 20, fontSize: '20px' }}>${this.state.OverallAmount}</div>
              {/* <div style={{ width: '100%', paddingBottom: 40 }}>
              <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: (isMobile) ? 100 : 200, fontWeight: 'bold', marginRight: 20 }}
                disabled={this.state.disabled}
                onClick={() => this.CleanData()}
                class="btn my-cusomized-button">
                                清空
              </button>
              <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 100 : 200, fontWeight: 'bold' }}
                disabled={this.state.disabled}
                onClick={() => this.ConfirmSaveBet()}
                class="btn my-cusomized-button">
                                发送
              </button>
            </div> */}
            </div>
          </div>
          <div style={{ width: '18%', marginLeft: 5, marginTop: 0, heigth: 'auto' }}>
            {this.state.loading ? (
              <div style={{ flexDirection: 'column', width: 'auto' }}>
                <div>
                  <img src={Loading3} alt="Logo" className="loadingimagesbet" style={{ marginBottom: 30, marginTop: 50 }} />
                  <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
                </div>
              </div>
            ) : (
              <>
                {this.state.ShowReceipt === true &&
                <div className="column" style={{ backgroundColor: 'white', width: '100%', borderColor: 'black', borderRadius: 5, marginLeft: 10 }}>
                  <div className="dcc row" style={{ marginBottom: 30, fontWeight: 'bold', fontSize: '20px' }}>
                    <div>{`${(this.state.SuccessFullCancel === true) ? 
                      language[this.props.currentLanguage].CancelSuccessfulldisplay
                      : language[this.props.currentLanguage].SuccessOrderBet }`}</div>
                    <div>
                      <GrFormClose
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({ ShowReceipt: false, Receipt: '' })
                        }}
                        size="35px"
                        color="#000"
                      />
                    </div>
                  </div>
                  <div className="column DisplayReceiptValue">
                    {this.state.ReceiptDisplay}
                  </div>
                  <button style={{ marginRight: 5 }}
                    className="btn my-cusomized-button BoxVersionReceiptButton" onClick={() => {this.CopyToC(this.state.Receipt)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                  <button
                    onClick={() => this.CancelBetAPI()}
                    disabled={this.state.DisabledCancel}
                    className="btn my-cusomized-button BoxVersionReceiptButton" >
                    {language[this.props.currentLanguage].CancelTicket}
                  </button>
                </div>
                }
              </>
            )}
          </div>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(betting);
